import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Select from 'react-select';
import axios from "axios";
import { MyContext } from "../Context/Context";
import * as Yup from 'yup';
import { useQuery } from "react-query";
import { Formik, useFormik } from "formik";
import ModelPop from "./ModelPop";
import Datetable from "./Datetable";
export default function Teamwork() {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { url } = useContext(MyContext);
  


  
  const initialValues = {
    name: '',
    email: '',
    internal_email: '',
    password: '',
    password_confirmation: '',
    phone: '',
    role: '',
    status: '',
    department_id: '',
    section_id: '',
    image: null,
    permissions: '[]',
    permissions: '[]',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required')
  });

  const onSubmit = async (values) => {
    try {
      await axios.post(`${url}api/employees/create`, values);
      console.log('Data sent successfully!');
    } catch (error) {
      console.error('Failed to send data:', error);
    }
    console.log(values);
  };
  const handleImageChange = (e) => {
    formik.setFieldValue('image', e.target.files[0]);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });




  const getdate = async () => {
    try {
      const token = localStorage.getItem('token'); // Get token from local storage
      const response = await axios.post(
        `${url}api/employees/create`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}` // Include token in request headers
          }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
  };
  
  const { data: date, isLoading, error } = useQuery('employees', () => getdate());
  
  // console.log(date);
  
  
  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };




  const [selectedOption, setSelectedOption] = useState(null);
  const colourOptions = [
    { value: 'retd', label: 'Red' },
    { value: 'r', label: 'Blue' },
    { value: 'grt43een', label: 'Green' },
    { value: 'a', label: 'Green' },
    { value: 'd', label: 'Green' },
    { value: 'tt', label: 'Green' },]
  const colourOptionss = [
    { value: 'retd', label: 'Red' },
    { value: 'r', label: 'Blue' },
    { value: 'grt43een', label: 'Green' },
    { value: 'a', label: 'Green' },
    { value: 'd', label: 'Green' },
    { value: 'tt', label: 'Green' },]

  
  const colums = [
    {
      name: " الأسم",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "البريد الألكترونى ",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "	ايميل المرسلة	",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "   المسمى الوظيفى	",
      selector: (row) => row.row,
      sortable: true,
    },
    {
      name: "    التخصص",
      selector: (row) => row.datea,
      sortable: true,
    },
    {
      name: "    اخر تسجيل الدخول		",
      selector: (row) => row.updaute.slice(0, 10),
      sortable: true,
    },

    {
      name: "الحاله",
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            className="w-50"
            title=" الحاله"
          >
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </div>
      ),
      sortable: true,
      selector: (row) => row.Processess,
    },
    {
      name: "العمليات",
      selector: (row) => row.Processes,
      sortable: true,
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            title="العمليات"
            className=""
          >
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1">
                <p>
                  <i className="fas fa-edit"></i>
                </p>
                <p className="mx-2">التحديث</p>
              </div>
            </Dropdown.Item>
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1 pb-2">
                <p className="mx-2">
                  <i className="fa-solid fa-x"></i>
                </p>
                <p>حذف</p>
              </div>
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];
  const data = [
    {
      id: 1,
      name: "Ahmed  Waled",
      email: "  .w@active4web.com	 ",
      age: "@active4web.com",
      row: "programmers",
      datea: "Web Design",
      updautee: "2024-04-17 09:21:14		",
      updaute: "2024-04-17 09:21:14		",
      
      Processes: "Process",
      Processess: "Process",
      Processes: "Process",
    },
    {
      id: 1,
      name: "mahmed  Waled",
      email: "  .w@active4web.com	 ",
      age: "@active4web.com",
      row: "programmers",
      datea: "Web Design",
      updautee: "2024-04-17 09:21:14		",
      updaute: "2024-04-17 09:21:14		",
      
      Processes: "Process",
      Processess: "Process",
      Processes: "Process",
    },
  
  ];
  const [records, setrecords] = React.useState(data);

  function handleFilter(event) {
    const newData = data.filter((row) => {
      return row.name
        .toLocaleUpperCase()
        .includes(event.target.value.toLocaleUpperCase());
    });
    setrecords(newData);
  }
  return (
    <>
      <Link className="fw-bold text-dark" to={"/"}>
        الرئسيه
      </Link>
      <Link className="text-muted mx-2 text-decoration-none mx-4">
        {" "}
        فريق العمل{" "}
      </Link>

      <div className="mt-5 d-flex">
        <p className="mx-2">
          <i className="fa fa-cog" aria-hidden="true"></i>
        </p>
        <p className="text-muted">  فريق العمل </p>
      </div>

      <div className="mt-4 d-flex">
        {/* <button className="btn btn-danger px-3">
          {" "}
          <i className="fa-solid fa-xmark"></i> حذف{" "}
        </button> */}
        <button
          className="btn bg-color p-1 text-white mx-3"
          onClick={handleModalShow}
        >
          {" "}
          <i className="fa-solid fa-plus"></i> اضافه{" "}
        </button>
      </div>

      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">
        <input
          type="search"
          placeholder="search"
          className="text-end form-control bg-gray mt-3 mb-3"
          onChange={handleFilter}
        />
      </div>
 <Datetable></Datetable>
      {/* ----------- end-Table ------------------------*/}

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-5 pt-3 text-color "> الأضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row mx-4">
     <ModelPop></ModelPop>

         
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center d-center mb-4 mt-4">
        <button type="submit " className="btn bg-color text-white px-3">حفظ</button>

          {/* <Button
            variant="secondary"
            className="px-4 mx-4"
            onClick={handleModalClose}
          >
            إغلاق
          </Button>
          <Button variant="primary" type="submit" className="px-4" onClick={handleModalClose}>
            حفظ
          </Button> */}

        </Modal.Footer>
      </Modal>
    </>
  );
}
