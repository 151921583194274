import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Ubdateuser from './Ubdateuser';

export default function Datetable() {
    const [records, setRecords] = useState([]);
    const [show, setShow] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null); // تعريف الحالة المحددة لـ userId

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const columns = [
        {
            name: " الأسم",
            selector: (row) => row.name,
            sortable: false,
        },
        {
            name: "البريد الألكترونى ",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "	ايميل المرسلة	",
            selector: (row) => row.internal_email,
            sortable: true,
        },
        {
            name: "   المسمى الوظيفى	",
            selector: (row) => row.department.name,
            sortable: true,
        },
        {
            name: "    التخصص",
            selector: (row) => row.section.name,
            sortable: true,
        },
        {
            name: "    اخر تسجيل الدخول		",
            selector: (row) => row.updated_at?.slice(0, 10),
            sortable: true,
        },
        {
            name: "الحاله",
            cell: (row) => (
                <div className="w-75 ">
                    {row.status == 1 ? <div className='bg-color text-center text-white border rounded p-1'>الحساب مفعل</div>:
                   <div className='bg-color text-center text-white border-r rounded'>الحساب غير مفعل</div>
                    }
                </div>
            ),
            sortable: true,
            selector: (row) => row.Processess,
        },
        {
            name: "العمليات",
            cell: (row) => (
                <div className=" d-flex ">
                    <button className='btn btn-danger d-flex  btn-sm mx-' onClick={() => handleDelete(row)}> 
                        <p className='px-1'> <i className="fa-solid fa-x"></i></p>
                        <p>حذف</p>
                    </button>
                    <button className='btn btn-primary d-flex btn-sm mx-1' onClick={() => handleRowClick(row.id)}>
                        <p className='px-1'> <i className="fas fa-edit"></i></p>
                        <p>تعديل</p>
                    </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.bokaramstore.com/api/employees', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setRecords(response.data.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);

    // دالة للحذف
    const handleDelete = (row) => {
        const token = localStorage.getItem('token');
        const id = row.id;
        axios.delete(`https://api.bokaramstore.com/api/employees/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            axios.get('https://api.bokaramstore.com/api/employees', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                setRecords(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
        })
        .catch(error => {
            console.error('Error deleting data:', error);
        });
    };

    const handleRowClick = (id) => {
        setSelectedUserId(id);
        handleShow();
    };
    
    return (
        <>
            <DataTable
                columns={columns}
                data={records}
                selectableRows
                pagination
            />

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>تعديل بيانات المستخدم</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Ubdateuser userId={selectedUserId} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        اغلاق
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
