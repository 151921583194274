import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Sidnar from '../Sidbar/Sidnar';

export default function Layout() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token); // Set isAuthenticated to true if token exists, false otherwise
  }, []);

  return (
    <>
      {isAuthenticated && <Sidnar />}
      <div className="container-fluid mx-auto">
        <div className="row">
          {isAuthenticated && (
            <div className="col-2">
              <Navbar />
            </div>
          )}
          <div className="col-10">
            <div className='mx-4'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
