import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { MyContext } from "../Context/Context";
import toast, { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";
import { BallTriangle, Oval } from "react-loader-spinner";
// import { , toast } from "react-toastify";


export default function Login() {
  
  const [email, setEmail] = useState(''); // State to store email
  const [password, setPassword] = useState(''); // State to store password
  const [error, setError] = useState(''); // State to store error message
  const [loading, setLoading] = useState(false); // State to store loading status
  const [data, setData] = useState(''); // State to store fetched data

  const { url , device_token } = useContext(MyContext);
  // console.log(device_token);
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
// console.log(device_token);
  let navegate = useNavigate()

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when form is submitted
    
    try {
      console.log("Sending device_token:", device_token);
      const response = await fetch(`${url}api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password ,device_token})
      });
  
      const data = await response.json();
      setError(data.message);
      console.log(data , "Response from server");
  
      // Check if the response contains a token
      if (data.data.token) {
        // Store the token in localStorage
        localStorage.setItem('token', data.data.token);
      } else {
        throw new Error('No token found in response');
      }
  
      setData(data);
    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
};

  
  useEffect(() => {
    if(data && data.message === "success"){
      console.log(data);
      toast.success(data.message, {
        position: "top-center"
      });
      localStorage.setItem('token', data.data.token);
      navegate("/");
      window.location.href = window.location.href;
    // } else {
    //   toast.error("email or password incorrect", {
    //     position: "top-center"
    //   });
    //   console.log("false");
    }
  }, [data]); // Include data as a dependency
  


  // console.log(data);

  const handleReload = () => {
    setError('');
    // Add any other logic you need before reloading
    window.location.reload();
  };


  return (
    <div className="row">
    <div className="col-4"></div>
    <div className="col-6">
    {loading? 
    
    <div className="d-center flex-column vh-75 overflow-hidden w-100">
    
    <div className=" w-100  border border-2">
<div className="d-center pt-4 p-5">
<Oval
  visible={true}
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
</div>
     </div>
     </div>
     
     :
     <div className="d-center flex-column vh-75 overflow-hidden">
     <div className=" mx-auto w-100  border border-2">
       <div className="p-3">
       {error && <div className="alert alert-danger mt-3 p-2">{error}</div>}
         <h4 className="text-center mt-3">تسجيل الدخول</h4>
         <input type="text" className="input-custom mt-3 w-100" value={email} onChange={handleEmailChange} />
         <input type="password" className="input-custom mt-3 w-100" value={password} onChange={handlePasswordChange} />
        
        {loading? <>   <button className="btn btn-dark mt-3 w-25 p-2 rounded-5" onClick={handleSubmit}>
 
        <i className="fa-solid fa-spinner fa-spin"></i>
         </button></>:<button className="btn btn-dark mt-3 w-100 p-2 rounded-5 bg-color border-0" onClick={handleSubmit}>
           تسجيل دخول

         </button>}
         {/* {loading && <p>Loading...</p>} */}
         {/* {data && <p>Welcome, {data.username}!</p>} */}
         <div className="d-flex justify-content-between mt-3">
           <Link className="pointer text-muted bg-white border-0  btn btn-primary d-center d-flex mt-3">
             نسيت كلمة المرور؟
           </Link>
           <div className="input-group-text">
             <p className="fw-bold text-muted mx-2">تذكرني</p>
             <input
               className="form-check-input mt-0 border-red checkbox-red"
               type="checkbox"
               value=""
               aria-label="Checkbox for following text input"
             />
           </div>
         </div>
         {/* <button className="btn btn-danger mt-3 w-100 p-2 rounded-5" onClick={handleReload}>
           Reload
         </button> */}
         
       </div>
     </div>
   </div> }
     
    </div>
    <div className="col-4"></div>
    </div>
  );
}
