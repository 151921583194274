import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { MyContext } from '../Context/Context';
import axios from 'axios';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import { BallTriangle, TailSpin } from 'react-loader-spinner';

export default function ModelPop() {
  const { url } = useContext(MyContext);
const [selectdate, setselectdate] = useState([])
const [selectedRole, setSelectedRole] = useState('');
const [department, setdepartment] = useState([])
const [section, setsection] = useState([])
//   const axios = require('axios');
// const [loading, setLoading] = useState(false); // حالة للتحميل
const [showSubmitButton, setShowSubmitButton] = useState(true);

   // دالة للتعامل مع تغيير قيمة الـ select
   const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
};

// عند الإرسال إلى الـ API، قم بإرسال selectedRole إلى الـ API
const handleSubmit = () => {
    // إرسال selectedRole إلى الـ API
    // console.log('الدور المحدد:', selectedRole);
};



// الحصول على عنوان الـ API من localStorage
// const url = localStorage.getItem('BaseUrl') 

async function getRoles() {
    const token = localStorage.getItem('token');
    if (!token) {
        // console.error('لم يتم العثور على رمز المصادقة في localStorage');
        throw new Error('لا يوجد رمز مصادقة في localStorage');
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${token}` // تعيين رمز المصادقة في هيدر الطلب
        }
    };

    try {
        const response = await axios.get(`https://api.bokaramstore.com/api/roles`, config);
        // console.log(response.data.data);
        setselectdate(response?.data?.data)
    } catch (error) {
        // console.error('حدث خطأ:', error.message);
        throw error;
    }
}

useEffect(() => {
    getRoles();
}, [])




async function department_id() {
    const token = localStorage.getItem('token');
    if (!token) {
        // console.error('لم يتم العثور على رمز المصادقة في localStorage');
        throw new Error('لا يوجد رمز مصادقة في localStorage');
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${token}` // تعيين رمز المصادقة في هيدر الطلب
        }
    };

    try {
        const response = await axios.get(`https://api.bokaramstore.com/api/departments`, config);
        // console.log(response.data.data);
        setdepartment(response?.data?.data)
        // console.log(response?.data?.data);
        // setdepartment_id(response?.data?.data)
        
    } catch (error) {
        // console.error('حدث خطأ:', error.message);
        throw error;
    }
}

useEffect(() => {
    department_id();
}, [])




async function section_id() {
    const token = localStorage.getItem('token');
    if (!token) {
        // console.error('لم يتم العثور على رمز المصادقة في localStorage');
        throw new Error('لا يوجد رمز مصادقة في localStorage');
    }

    const config = {
        headers: {
            'Authorization': `Bearer ${token}` // تعيين رمز المصادقة في هيدر الطلب
        }
    };

    try {
        const response = await axios.get(`https://api.bokaramstore.com/api/sections`, config);
        // console.log(response.data.data);
        setsection(response?.data?.data)
        // console.log(response?.data?.data);
        // setdepartment_id(response?.data?.data)
        
    } catch (error) {
        // console.error('حدث خطأ:', error.message);
        throw error;
    }
}

useEffect(() => {
    section_id();
}, [])
// console.log(selectdate , "selectdateselectdateselectdate");

  const initialValues = {
    name: '',
    email: '',
    internal_email: '',
    image: null,
    phone: '', // Add phone field
    password: '', // Add password field
    password_confirmation: '', // Add password_confirmation field
    status: '', // Add password_confirmation field
    role:"",
    department_id:"",
    section_id:""
  };
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    internal_email: Yup.string().email('Invalid email address').required('Internal email is required'),
    phone: Yup.string().required('Phone is required'), // Validation for phone
    password: Yup.string().required('Password is required'), // Validation for password
    password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Password confirmation is required'), // Validation for password_confirmation
    status: Yup.string().required('Select an option'), // Validation for the select option
    role: Yup.string().required('Select an option'), // Validation for the select option
    department_id: Yup.string().required('Select an option'), // Validation for the select option
    section_id: Yup.string().required('Select an option'), // Validation for the select option
 
});



// دالة للتعامل مع تغيير الدور
// دالة للتعامل مع تغيير الدور
const handleRoleChangee = (event) => {
    const selectedRole = event.target.value;
    // تحديث قيمة role في formik عند اختيار دور جديد
    formik.setFieldValue('role', selectedRole);
};
const departmentt = (event) => {
    const departmentt = event.target.value;
    // تحديث قيمة role في formik عند اختيار دور جديد
    formik.setFieldValue('department', department);
};
// const department_id = (event) => {
//     const selectedRole = event.target.value;
//     // تحديث قيمة role في formik عند اختيار دور جديد
//     formik.setFieldValue('department_id', selectedRole);
// };





const onSubmit = async (values) => {
    try {
        setShowSubmitButton(false); // إخفاء زر "Submit" وعرض زر "Loading"

        const token = localStorage.getItem('token'); // Get token from local storage
        const formData = new FormData(); // Create FormData object
        formData.append('name', values.name); // Append form fields
        formData.append('email', values.email); // Append email
        formData.append('internal_email', values.internal_email); // Append internal email
        formData.append('image', values.image); // Append image
        formData.append('phone', values.phone); // Append phone
        formData.append('password', values.password); // Append password
        formData.append('password_confirmation', values.password_confirmation); // Append password_confirmation
        formData.append('status', values.status); // Append status to the form data
        formData.append('role', values.role); // Append role to the form data
        formData.append('department_id', values.department_id); // Append department_id to the form data
        formData.append('section_id', values.section_id); // Append section_id to the form data

        // Logging formData for debugging
        // console.log(formData, 'Form Data:');

        // Sending form data to the API
        const response = await axios.post(`https://api.bokaramstore.com/api/employees/create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set content type for FormData
                'Authorization': `Bearer ${token}` // Include token in request headers
            },
        });

        // console.log('Data sent successfully!');
        // console.log('Response:', response.data.message); // Print the response

        if (response.data.message === 'success') {
            toast.success(response.data.message);
            toast.success('Successfully toasted!')
        } else {
            toast.error(response.data.message);
        }

        // إعادة عرض زر "Submit"
        setShowSubmitButton(true);

    } catch (error) {
        // console.error('Error:', error.response.data.errors); // Print error message and data
        setShowSubmitButton(true); // إعادة عرض زر "Submit" في حالة الخطأ
    }
};




  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  });
  
  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];
    // console.log('Selected File:', selectedFile);
    formik.setFieldValue('image', selectedFile);
  };
  



  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="col-4 mt-4">
          <label htmlFor="image">صورة الموظف</label>
          <input
            id="image"
            name="image"  
            type="file"
            className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={handleImageChange}
          />
        </div>
        <div className="col-4 mt-4">
          <label htmlFor="name">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            className="w-75 form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            placeholder="Name"
          />
      <div >
      {formik.touched.name && formik.errors.name ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.name}</div>
          ) : null}
      </div>
        </div>
        <div className="col-4 mt-4">
          <label htmlFor="email">البريد الإلكتروني</label>
          <input
            id="email"
            name="email"
            type="email"
            placeholder="البريد الإلكتروني"
            className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="col-4 mt-4">
          <label htmlFor="internal_email">ايميل المرسلة</label>
          <input
            id="internal_email"
            name="internal_email"
            type="email"
            placeholder="ايميل المرسلة"
            className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.internal_email}
          />
          {formik.touched.internal_email && formik.errors.internal_email ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.internal_email}</div>
          ) : null}
        </div>
        <div className="col-4 mt-4">
          <label htmlFor="phone"> رقم الهاتف</label>
          <input
            id="phone"
            name="phone"
            type="phone"
            placeholder="ايميل المرسلة"
            className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.phone}</div>
          ) : null}
        </div>



        <div className="col-4 mt-4">
          <label htmlFor="password">  الرقم السري</label>
          <input
            id="password"
            name="password"
            type="password"
            placeholder="ايميل المرسلة"
            className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="col-4 mt-4">
          <label htmlFor="password_confirmation">   تاكيد الرقم السري</label>
          <input
            id="password_confirmation"
            name="password_confirmation"
            type="password"
            placeholder=" تاكيد الرقم السري"
            className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password_confirmation}
          />
          {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.password_confirmation}</div>
          ) : null}
        </div>

        <div className="col-4 mt-4">
          <label htmlFor="status"> حالة الاكونت</label>
          <select
            id="status"
            name="status"
            className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.status}
          >
            <option value="" disabled>اختر...</option>
            <option value="1">تفعيل الاكونت</option>
            <option value="2">الغاء تفعيل الاكونت</option>
          </select>
          {formik.touched.status && formik.errors.status ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.status}</div>
          ) : null}
        </div>
        <div className="col-4 mt-4">
          <label htmlFor="role"> role</label>
          <select
    id="role"
    name="role"
    className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
    onChange={handleRoleChangee}
    value={formik.values.role} // استخدم formik.values.role كقيمة افتراضية لـ role
>
    <option value="" disabled>اختر...</option>
    {selectdate?.map(item => (
        <option key={item.value} value={item.value}>{item.name}</option>
    ))}
</select>



          {formik.touched.role && formik.errors.role ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.role}</div>
          ) : null}
        </div>
   <div className="col-4 mt-4">
          <label htmlFor="department_id"> departments</label>
          <select
                    id="department_id"
                    name="department_id"
                    className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.department_id}
                >
                    <option value="" disabled>اختر...</option>
                    {department.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                </select>


          {formik.touched.department_id && formik.errors.department_id ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.department_id}</div>
          ) : null}
        </div>
   <div className="col-4 mt-4">
          <label htmlFor="section_id"> section</label>
          <select
                    id="section_id"
                    name="section_id"
                    className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.section_id}
                >
                    <option value="" disabled>اختر...</option>
                    {section.map(item => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                </select>


          {formik.touched.section_id && formik.errors.section_id ? (
            <div className="bg-alert w-75 mt-2 p-1">{formik.errors.section_id}</div>
          ) : null}
        </div>

        

      </div>
   {showSubmitButton && (
    <button type="submit" className="btn btn-primary mt-4 p-2" disabled={!formik.isValid} onClick={handleSubmit}>
        اضافه
    </button>
)}
{!showSubmitButton && (
    <button type="button" className="btn border-0 mt-4" disabled>
 <TailSpin
  visible={true}
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass=""
  />
    </button>
)}


    </form>
  );
}
